<!-- 登录页 -->
<template>
  <div class="main" v-if="isPc">
    <img src="../images/logotitle6.png" alt="" style="width:250px;height:35px;position:absolute;top:20px;left:35px;z-index: 10;">
    <!-- <img src="../images/newbg.png" alt="" > -->
    <div >
      
      <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="60px"
      class="demo-ruleForm"
      style="border-radius:20px;display: flex;align-items: center;box-shadow:rgba(209, 222, 212, 0.5) 0px 0px 10px 5px;"
    >
    <img src="../images/newbg2.png" alt="" style="width: 463px;height: 520px;">
    <div style="width: 463px;height: 520px;background: #fff;display: flex;flex-direction: column;justify-content: center;align-items: center;border-radius:0 28px 28px 0;">
      <div>
        <div class='title'>成为世界</div>
        <div class='title' style="margin-bottom:24px">领先的美好生活服务商</div>
      </div>
      <el-form-item  prop="name">
        <el-input v-model="ruleForm.name" @keyup.enter.native='inpsubmit' style="width:270px" placeholder="请输入您的账号" prefix-icon="el-icon-user"></el-input>
      </el-form-item>
      <el-form-item  prop="password">
        <el-input v-model="ruleForm.password" type='password' @keyup.enter.native='inpsubmit' show-password style="width:270px" placeholder="请输入您的密码" prefix-icon="el-icon-lock"></el-input>
      </el-form-item>
      <el-form-item style="margin-left:0" class="c1">
        <div style="width:280px;background: #64A774;border-radius: 13px;line-height: 40px;height: 40px;text-align: center;color: #fff;margin-left:0" @click="submitForm('ruleForm')">登录</div>
      </el-form-item>
    </div>
    </el-form>
    </div>
    
    <div style="width:300px;margin:0 auto; padding:20px 0;position: absolute;left: 50%;transform: translate(-50%,0);bottom: 5%;">
          <a target="_blank" href=" https://beian.miit.gov.cn/" style="display:flex;text-decoration:none;height:20px;line-height:20px;"><img src="../images/record.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#fff;text-align:center;color:#CECECE">浙公网安备 33010202002041号 浙ICP备2021037496号-1</p ></a>
          </div>
  </div>
  <div v-else style="background:#fff;height: 100%;">
      <img src="../images/newbg3.png" alt="" style="width: 100%;height: 343px;" />
      <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="60px"
      style="border-radius:20px;display: flex;align-items: center;"
    >
    <div style="width: 463px;background: #fff;display: flex;flex-direction: column;justify-content: center;align-items: center;margin-top: 20px;">
      <div>
        <div class='title'>成为世界</div>
        <div class='title' style="margin-bottom:24px">领先的美好生活服务商</div>
      </div>
      <el-form-item  prop="name">
        <el-input v-model="ruleForm.name" @keyup.enter.native='inpsubmit' style="width:270px" placeholder="请输入您的账号" prefix-icon="el-icon-user"></el-input>
      </el-form-item>
      <el-form-item  prop="password">
        <el-input v-model="ruleForm.password" type='password' @keyup.enter.native='inpsubmit' show-password style="width:270px" placeholder="请输入您的密码" prefix-icon="el-icon-lock"></el-input>
      </el-form-item>
      <el-form-item style="margin-left:0" class="c1">
        <div style="width:220px;background:linear-gradient(#8DD193,#B3DF93);border-radius: 13px;line-height: 43px;height: 43px;text-align: center;color: #fff;margin-left:0" @click="submitForm('ruleForm')">
        <!-- <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button> -->
        登录
        </div>
      </el-form-item>
    </div>
    </el-form>
  </div>
</template>

<script>
import {Login,loginNew} from '../apis/index'
import sha1 from 'sha1'
export default {
  data() {
    return {
      isPc:true,
      ruleForm: {
        name: "",
        password:''
      },
      rules: {
        name: [{ required: true, message: "请输入您的账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入您的密码", trigger: "blur" }],
      },
    };
  },
  created(){
    let width=window.screen.width
        if(width>400){
            this.isPc=true
        }else{
            this.isPc=false
        }
  },
  methods: {
      inpsubmit(){
          this.submitForm('ruleForm')
      },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params={
            account:this.ruleForm.name,
            // id:parseInt(this.ruleForm.name),
            pwd:this.ruleForm.password
          }
          loginNew(params).then(res=>{
            // console.log(res.data.data.user.roleId)
            if(res.data.code==200){
              this.$message({
                showClose: true,
                message: '登陆成功',
                type: 'success'
              });
              this.$store.commit('setToken',res.data.data.token)
               this.$store.commit('setBrandId',res.data.data.brandId)
               this.$store.commit('setUserInfo',res.data.data.name)
              // this.$store.commit('setUserInfo',res.data.data.user)
              // this.$store.commit('setPermissionsList',res.data.data.permission)
              this.$store.commit('setPermissionsList',res.data.data.permissionRoleVo.children)
              if(res.data.data.userId){
                this.$store.commit('setUserId',res.data.data.userId)
              }
              if(res.data.data.roleId){
                this.$store.commit('setRoleId',res.data.data.roleId)
              }else{
                this.$store.commit('setRoleId','')
              }
              setTimeout(()=>{
                // this.$router.push({path:'/home'})
                if(res.data.data.permissionRoleVo.children[0].uri){
                this.$router.push({path:res.data.data.permissionRoleVo.children[0].uri})
                }else{
                  this.$router.push({path:res.data.data.permissionRoleVo.children[0].children[0].uri})
                }
              },500)
              // if(res.data.data.user.roleId==2){//管理员
              //   setTimeout(()=>{
              //   this.$router.push({path:'/agentHome'})
              //   // location.reload()
              // },500)
              // }else if(res.data.data.user.roleId==4){//导师
              //    setTimeout(()=>{
              //   this.$router.push({path:'/distribution'})
              // },500)
              // }else if(res.data.data.user.roleId==5){//审核员
              //    setTimeout(()=>{
              //   this.$router.push({path:'/audit'})
              // },500)
              // }else if(res.data.data.user.roleId==10){//店长
              //    setTimeout(()=>{
              //   this.$router.push({path:'/beautician'})
              // },500)
              // }else{
              //    setTimeout(()=>{
              //   this.$router.push({path:'/agentHome'})
              // },500)
              // }
              
            }else{
              this.$message.error(res.data.msg)
            }
          })

          // console.log(sha1(this.ruleForm.password))
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang='less' scoped>
.demo-ruleForm {
  // width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.main {
  // background-image:url(../images/manageBg.png) !important;
  background: #F2F7F3;
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  // background: #fff;
}
.title{
  font-size: 28px;
  font-weight: bold;
  // margin-bottom: 40px;
}

/deep/.el-form-item__content {
  margin-left: 0 !important
}
</style>

